<!--
 * @Author: yaolunmao
-->
<template>
  <component :is="component_prop.type" :prop_data="component_prop"></component>
</template>
<script>
import Vue from 'vue'
let importComponents = {}
export default {
  components: importComponents,
  props: ['svgInfoData', 'component_prop', 'box', 'pointNameChecked', 'pointChecked'],
  data() {
    return {
      componentTag: ''
    }
  },
  watch: {
    // "pointNameChecked"(val){
    //   console.log(val)
    // }
    //  "box.background"(val){
    //  }
  },
  provide: {},
  created() {
    this.svgInfoData.forEach((f, i) => {
      let componentInfo = {
        template: f.template,
        props: f.props
      }
      Vue.component(f.type, componentInfo)
      importComponents[f.type] = componentInfo
    })
  },
  mounted() {
    // console.log(this.pointNameChecked)
  },
  methods: {}
}
</script>
<style scoped></style>
